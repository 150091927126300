var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"w-100 h-100 p-4 d-flex flex-column justify-content-between",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.change)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 mb-3"},[_c('label',{staticClass:"form-label campo-obrigatorio",attrs:{"for":"razaoSocial"}},[_vm._v("Razão Social")]),_c('validation-provider',{attrs:{"name":"razaoSocial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.empresa.razaoSocial),expression:"empresa.razaoSocial"}],staticClass:"form-control",class:classes,attrs:{"type":"text","id":"razaoSocial","maxlength":"60"},domProps:{"value":(_vm.empresa.razaoSocial)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.empresa, "razaoSocial", $event.target.value)}}}),_vm._l((errors),function(error){return _c('div',{key:error,staticClass:"invalid-feedback"},[_c('span',{staticClass:"erro"},[_vm._v(_vm._s(error))])])})]}}],null,true)})],1),_c('div',{staticClass:"col-lg-4 mb-3"},[_c('label',{staticClass:"form-label campo-obrigatorio",attrs:{"for":"cnpj"}},[_vm._v("CNPJ")]),_c('validation-provider',{attrs:{"name":"cnpj","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.empresa.cnpj),expression:"empresa.cnpj"},{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticClass:"form-control",class:classes,attrs:{"type":"tel","id":"cnpj","readonly":_vm.edit},domProps:{"value":(_vm.empresa.cnpj)},on:{"blur":function($event){return _vm.formatarCNPJ($event.target.value)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.empresa, "cnpj", $event.target.value)}}}),_vm._l((errors),function(error){return _c('div',{key:error,staticClass:"invalid-feedback"},[_c('span',{staticClass:"erro"},[_vm._v(_vm._s(error))])])})]}}],null,true)})],1)]),_c('div',{staticClass:"pt-4 w-100 d-flex justify-content-center"},[_c('router-link',{staticClass:"btn btn-light",attrs:{"to":{ name: 'OperadorPortuarioPesquisar' }}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Cancelar")]),_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit"}},[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Salvar ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }